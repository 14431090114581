import request from '@/utils/request'

/**
 * 获取威弘系统模块列表
 * @returns
 */
export function listSystemsApi() {
  return request({
    baseURL: process.env.VUE_APP_PLATFORM_API,
    url: '/product/list',
    method: 'get',
    params: {}
  })
}

/**
 * 获取威弘系统详情
 * @returns
 */
export function getSystemInfoApi(productId) {
  return request({
    baseURL: process.env.VUE_APP_PLATFORM_API,
    url: '/product/info',
    method: 'get',
    params: { productId }
  })
}

/**
 * 提交申请开通的表单
 * @param {*} data
 * @returns
 */
export function applySystemApi(data) {
  return request({
    baseURL: process.env.VUE_APP_PLATFORM_API,
    url: '/product/open/msg',
    method: 'post',
    data
  })
}

/**
 * 提交咨询
 * @param {*} data
 * @returns
 */
export function saveConsultApi(data) {
  return request({
    baseURL: process.env.VUE_APP_PLATFORM_API,
    url: '/consult/record/batchSubmit',
    method: 'post',
    data
  })
}
