<!--
 * @Author: hwu
 * @Date: 2023-12-19 16:04:24
 * @Description: 首页 -> 系统列表
 * @LastEditTime: 2024-03-05 18:13:08
-->
<template>
  <div class="system-wrapper">
    <div class="system-list">
      <div class="system-box" v-for="(system, index) in systemList" :key="index">
        <div class="system-item" @click="showSystemDialog(system)">
          <div class="system-item-header">
            <span class="system-item-header_title">{{ system.productName }}</span>
          </div>
          <div class="system-item-body">
            <div class="system-item-content">
              <template v-if="system.isFree">
                <div class="system-item-content_row">免费</div>
              </template>
              <template v-else>
                <div class="system-item-content_row">{{ system.prefix }}{{ system.standardFee }}{{ system.unit }}</div>
              </template>
            </div>
          </div>
          <div class="system-item-footer">
            <a class="system-item-footer_link" href="javascript: void(0)">&#62;&#62;</a>
          </div>
        </div>
      </div>
    </div>
    <!--    功能介绍弹窗-->
    <el-dialog class="system-dialog" :visible.sync="systemDialogShow" :show-close="false" width="50vw">
      <div slot="title" class="system-dialog-title">
        <span>《{{ selectedSystem.productName }}》</span>
      </div>
      <div class="system-dialog-content">
        <div class="system-dialog-charge">
          <div class="system-dialog-charge_row">
            <div class="system-dialog-charge_row-left">
              <span class="charge-text-main">· 服务费：</span>
              <span class="charge-text-normal" v-if="selectedSystem.isFree">免费</span>
              <span class="charge-text-normal" v-else>{{ selectedSystem.prefix || '' }}{{ selectedSystem.standardFee }}{{ selectedSystem.unit }}</span>
              <span class="charge-text-normal" v-if="selectedSystem.contractFee">（电子合同{{ selectedSystem.contractFee }}元/份）</span>
            </div>
            <div class="system-dialog-charge_row-right">
              <div class="charge-text-normal color-text-main" v-if="selectedSystem.standardFreeDay">免费使用{{ selectedSystem.standardFreeDay }}天</div>
              <div class="charge-text-normal color-text-main" v-else-if="selectedSystem.standardFreeMonth">免费使用{{ selectedSystem.standardFreeMonth }}个自然月</div>
            </div>
          </div>
          <div class="system-dialog-charge_row" v-if="selectedSystem.feeRemark">
            <div class="system-dialog-charge_row-left">
              <div class="charge-text-desc">{{ selectedSystem.feeRemark }}</div>
            </div>
          </div>
        </div>
        <div class="system-dialog-info" v-if="selectedSystem.illustrate && selectedSystem.illustrate.length > 0">
          <div class="system-dialog-info_title">· 功能说明</div>
          <div class="system-dialog-info_content">
            <div class="func-row" v-for="(func, index) in selectedSystem.illustrate" :key="index">
              <span class="func-row-num">{{ index + 1 }}</span>
              <span class="func-row-text">{{ func }}</span>
            </div>
          </div>
        </div>
        <div class="system-dialog-info" v-if="selectedSystem.conditionProduct && selectedSystem.conditionProduct.length > 0">
          <div class="system-dialog-info_title">· 前置条件</div>
          <div class="system-dialog-info_content">
            <span>{{ selectedSystem.conditionProduct | conditionProductFilter }}</span>
          </div>
        </div>
        <div class="system-dialog-info" v-if="selectedSystem.deviceList && selectedSystem.deviceList.length > 0">
          <div class="system-dialog-info_title">· 所需设备</div>
          <div class="system-dialog-info_content">
            <div class="system-dialog-device" v-for="(device, index) in selectedSystem.deviceList" :key="index" @click="showDeviceDialog(device)">
              <div class="system-dialog-device_img">
                <el-image class="device-img" fit="cover" :src="$transferQnyUrl(device.oneUrl, 400)" />
              </div>
              <div class="system-dialog-device_info">
                <div>
                  <span class="device-name">【{{ device.deviceName }}】</span>
                  <span class="device-name color-text-main" v-if="device.mustRemark">（{{ device.mustRemark }}）</span>
                  <span class="device-split">|</span>
                  <span>价格：{{ device.devicePrice }}{{ device.unit }}</span>
                  <template v-if="device.deviceUse">
                    <span class="device-split">|</span>
                    <span>设备用途：{{ device.deviceUse }}</span>
                  </template>
                  <template v-if="device.parameter">
                    <span class="device-split">|</span>
                    <span>配置数量：{{ device.parameter }}</span>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="system-dialog-footer">
        <el-button size="small" @click="systemDialogShow = false">返回</el-button>
        <el-button size="small" @click="consultDialogShow = true">咨询</el-button>
        <el-button size="small" type="primary" @click="showApplyDialog">开通系统</el-button>
      </div>
    </el-dialog>
    <!-- 设备详情弹窗 -->
    <el-dialog class="system-dialog" :visible.sync="deviceDialogShow" width="50vw">
      <div slot="title" class="system-dialog-title">
        <span>【{{ selectedDevice.deviceName }}】</span>
        <span class="color-text-main" v-if="selectedDevice.mustRemark">（{{ selectedDevice.mustRemark }}）</span>
      </div>
      <div class="system-dialog-content">
        <div class="system-dialog-info">
          <div class="system-dialog-info_title">单价：{{ selectedDevice.devicePrice }}{{ selectedDevice.unit }}</div>
          <div class="system-dialog-info_content">
            <span>{{ selectedDevice.remark }}</span>
          </div>
        </div>
        <div class="system-dialog-image">
          <div class="system-dialog-image_item" v-for="(img, index) in selectedDevice.urlList" :key="index">
            <el-image class="device-img" fit="cover" :src="$transferQnyUrl(img, 400)" :preview-src-list="deviceImgUrls" />
          </div>
        </div>
        <div class="system-dialog-info" v-if="selectedDevice.ruleList && selectedDevice.ruleList.length > 0">
          <div class="system-dialog-info_title">用途&配置数量</div>
          <div class="system-dialog-info_content">
            <div v-for="(rule, index) in selectedDevice.ruleList" :key="index">
              <span>{{ index + 1 }}. {{ rule }}</span>
            </div>
          </div>
        </div>
        <div class="system-dialog-info" v-if="selectedDevice.afterSaleServiceList && selectedDevice.afterSaleServiceList.length > 0">
          <div class="system-dialog-info_title">售后服务</div>
          <div class="system-dialog-info_content">
            <div v-for="(service, index) in selectedDevice.afterSaleServiceList" :key="index">
              <span>{{ index + 1 }}. {{ service }}</span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 申请开通 -->
    <el-dialog class="wh-dialog" :visible.sync="applyDialogShow" :title="`开通《${selectedSystem.productName}》`" :show-close="false" width="30%">
      <home-system-apply-form ref="SystemApplyForm" @onSuccess="applyDialogShow = false" />
      <div slot="footer" class="wh-dialog-footer">
        <el-button size="small" @click="applyDialogShow = false">返回</el-button>
        <el-button size="small" @click="consultDialogShow = true">咨询</el-button>
        <el-button size="small" type="primary" @click="submitApply">提交</el-button>
      </div>
    </el-dialog>
    <!-- 咨询 -->
    <home-system-consult-dialog :show.sync="consultDialogShow" />
  </div>
</template>
<script>
import HomeSystemApplyForm from './HomeSystemApplyForm'
import HomeSystemConsultDialog from './HomeSystemConsultDialog'
import { listSystemsApi, getSystemInfoApi } from '@/api/apply'
export default {
  components: { HomeSystemApplyForm, HomeSystemConsultDialog },
  data() {
    return {
      systemList: [],
      systemDialogShow: false,
      selectedSystem: {},
      deviceDialogShow: false,
      selectedDevice: {},
      applyDialogShow: false,
      consultDialogShow: false
    }
  },
  filters: {
    conditionProductFilter(val) {
      if (!val || val.length === 0) {
        return ''
      }
      const nameList = val.map((x) => `《${x.productName}》`)
      const connector = nameList[0].must ? '和' : '或'
      return `需开通${nameList.join(connector)}。`
    }
  },
  computed: {
    selectedSystemChargeDesc() {
      if (!this.selectedSystem.name) {
        return ''
      }
      const arr = []
      if (this.selectedSystem.initialCharge) {
        arr.push(this.selectedSystem.initialCharge)
      }
      if (this.selectedSystem.regularCharge) {
        arr.push(this.selectedSystem.regularCharge)
      }
      if (this.selectedSystem.extraCharge) {
        arr.push(this.selectedSystem.extraCharge)
      }
      return arr.join('，') + '。'
    },
    deviceImgUrls() {
      if (!this.selectedDevice.urlList || this.selectedDevice.urlList.length === 0) {
        return []
      }
      return this.selectedDevice.urlList.map((x) => this.$transferQnyUrl(x))
    }
  },
  created() {
    this.listSystems()
  },
  methods: {
    listSystems() {
      listSystemsApi().then((res) => {
        if (res.status !== '1' || !res.data) {
          this.systemList = []
          return false
        }
        this.systemList = res.data
      })
    },
    showSystemDialog(system) {
      getSystemInfoApi(system.productId).then((res) => {
        if (res.status !== '1' || !res.data) {
          this.$toast('数据异常')
          return false
        }
        this.selectedSystem = res.data
        this.systemDialogShow = true
      })
    },
    showDeviceDialog(device) {
      this.selectedDevice = device
      this.deviceDialogShow = true
    },
    showApplyDialog() {
      // this.systemDialogShow = false
      this.applyDialogShow = true
    },
    submitApply() {
      this.$refs.SystemApplyForm.submitApply()
    }
  }
}
</script>
<style lang="less" scoped>
.system-wrapper {
  width: 100%;

  width: 1680px;
  margin: 0 auto;
  padding: 36px 0;
  box-sizing: border-box;

  .system-list {
    display: flex;
    width: 100%;
    align-items: flex-start;
    // justify-content: space-between;
    flex-wrap: wrap;

    .system-box {
      flex: 0 0 392px;
      width: 100%;
      margin-bottom: 32px;
      // padding: 16px;
      box-sizing: border-box;
      &:not(:nth-child(4n)) {
        margin-right: 32px;
      }

      .system-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 267px;
        padding: 0 32px;
        background-color: @color-white;
        border-radius: 12px;
        box-sizing: border-box;

        .system-item-header {
          flex: 0 0 auto;
          width: 100%;
          padding: 40px 0 16px;
          text-align: center;
          box-sizing: border-box;

          .system-item-header_title {
            line-height: 24px;
            font-size: 26px;
            font-weight: bold;
            color: @color-text-main;
          }
        }

        .system-item-body {
          flex: 1;
          display: flex;
          width: 100%;
          align-items: center;
          text-align: center;

          .system-item-content {
            flex: 1;
            width: 100%;

            .system-item-content_row {
              line-height: 30px;
              font-size: 22px;
              color: @color-text-normal;
            }
          }
        }

        .system-item-footer {
          flex: 0 0 auto;
          width: 100%;
          padding: 12px 0;
          text-align: right;
          box-sizing: border-box;

          .system-item-footer_link {
            font-size: 20px;
            color: @color-primary;
          }
        }
      }
    }
  }
}

/deep/ .system-dialog {
  .el-dialog {
    margin-top: 6vh !important;

    .el-dialog__body {
      max-height: 68vh;
      padding-top: 16px;
      padding-bottom: 0;
      overflow-y: auto;
    }

    .el-dialog__footer {
      padding-top: 20px;
    }
  }
}
.system-dialog-title {
  line-height: 24px;
  font-size: 26px;
  font-weight: bold;
  color: @color-danger-sub;
}
.system-dialog-content {
  .system-dialog-charge {
    width: 100%;
    margin-bottom: 24px;
    .system-dialog-charge_row {
      display: flex;
      width: 100%;
      align-items: flex-start;
      flex-wrap: nowrap;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      .system-dialog-charge_row-left {
        flex: 1;
      }
      .system-dialog-charge_row-right {
        flex: 0;
        margin-left: 16px;
        white-space: nowrap;
      }
    }
    .charge-text-main {
      line-height: 40px;
      font-size: 24px;
      font-weight: bold;
      color: @color-text-main;
    }
    .charge-text-normal {
      line-height: 40px;
      font-size: 24px;
    }
    .charge-text-desc {
      line-height: 36px;
      font-size: 20px;
    }
  }
  .system-dialog-info {
    width: 100%;
    margin-bottom: 24px;

    .system-dialog-info_title {
      margin-bottom: 8px;
      line-height: 40px;
      font-size: 24px;
      font-weight: bold;
      color: @color-text-main;
    }

    .system-dialog-info_content {
      line-height: 36px;
      font-size: 20px;
      color: @color-text-normal;

      .system-dialog-device {
        display: flex;
        width: 100%;
        align-items: flex-start;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        .system-dialog-device_img {
          flex: 0 0 137px;
          font-size: 0;
          .device-img {
            display: block;
            width: 137px;
            height: 137px;
          }
        }
        .system-dialog-device_info {
          flex: 1;
          width: 100%;
          margin-left: 24px;
          line-height: 36px;
          color: @color-text-normal;

          .device-name {
            color: @color-danger-sub;
            font-weight: bold;
          }

          .device-split {
            margin: 0 12px;
          }
        }

        .system-dialog-device_desc {
          width: 100%;
          line-height: 36px;
          font-size: 24px;
          color: @color-text-normal;
        }
      }
    }
  }
  .system-dialog-image {
    display: flex;
    width: 100%;
    padding: 0 32px;
    margin-bottom: 32px;
    align-items: center;
    justify-content: flex-start;
    .system-dialog-image_item {
      flex: 0 0 auto;
      &:not(:last-child) {
        margin-right: 48px;
      }
      .device-img {
        width: 172px;
        height: 172px;
      }
    }
  }
}

.func-row {
  line-height: 36px;
  .func-row-num {
    display: inline-block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-size: 24px;
    text-align: center;
    color: @color-white;
    background-color: @color-text-sub;
    border-radius: 50%;
    transform: scale(0.66);
    transform-origin: left center;
    vertical-align: bottom;
  }
}
</style>
