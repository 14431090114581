<!--
 * @Author: hwu
 * @Date: 2023-10-13 10:05:10
 * @Description: file content
 * @LastEditTime: 2024-03-01 17:20:13
-->
<!--
 * @Author: chenzhuo.li
 * @Date: 2023/9/8
 * @Description: 首页
 -->
<template>
  <div class="page-container">
    <!-- 登录区域-->
    <div class="page-header">
      <div class="page-header-content">
        <!-- 顶部信息展示区域-->
        <div class="header-box">
          <div class="header-title">
            <div class="title-text">威弘系统</div>
          </div>
          <div class="header-sub-title">适合1-10000店规模各类餐企</div>
          <div class="header-desc">餐饮企业｜全业务链路｜数字化管理系统</div>
        </div>
        <!--登录表单区域-->
        <div class="login-box">
          <div class="login-tab">
            <div class="tab-item" v-for="(tabItem, tabIndex) in tabList" :key="tabIndex" @click="tabChange(tabItem)">
              <div class="item-text">
                <span class="default-text" :class="activeTab === tabItem.name ? 'active-text' : ''">{{
                    tabItem.label
                  }}</span>
                <div class="active-line" v-if="activeTab === tabItem.name"></div>
              </div>
            </div>
          </div>
          <div class="login-form">
            <!-- 扫码登录-->
            <div class="qr-box" v-show="activeTab === 'scan'">
              <div class="img-box">
                <vue-qr :logoSrc="qrLogoUrl" :logoMargin="4" :margin="0" :text="qrCodeText" :size="qrWidth"></vue-qr>
              </div>
              <div class="qr-text">使用《威弘App》扫码登录</div>
            </div>
            <!-- 账号密码登录-->
            <div class="password-box" v-show="activeTab === 'password'">
              <div class="input-item">
                <div class="item-right">
                  <el-input class="item-input" placeholder="请输入手机号" v-model="passwordForm.loginName" type="tel"
                            maxlength="11" clearable></el-input>
                </div>
              </div>
              <div class="input-item">
                <div class="item-right">
                  <el-input class="item-input" placeholder="请输入密码" v-model="passwordForm.password"
                            show-password></el-input>
                </div>
              </div>
              <div class="agree-box">
                <i @click="agreeStatus = !agreeStatus" class="iconfont"
                   :class="agreeStatus ? 'active icon-danxuan-xuanzhong' : 'icon-danxuan-weixuanzhong'"></i>
                <div class="agree-text">
                  <span class="normal-text" @click="agreeStatus = !agreeStatus">我已阅读并同意</span>
                  <a :href="userAgreementUrl" target="_blank" class="normal-text color-primary">《威弘用户服务协议》</a>
                  <span class="normal-text">和</span>
                  <a href="https://static.weihong777.com/agree/index.html" target="_blank"
                     class="normal-text color-primary">《隐私政策》</a>
                </div>
              </div>
              <div class="button-box">
                <button class="button-item" :disabled="!loginButtonCanClick" @click="loginValidate">登录</button>
              </div>
            </div>
            <!-- 验证码登录-->
            <div class="code-box" v-show="activeTab === 'code'">
              <div class="input-item">
                <div class="item-right">
                  <el-input class="item-input" placeholder="请输入手机号" v-model="codeForm.loginName" type="tel"
                            maxlength="11" clearable></el-input>
                </div>
              </div>
              <div class="input-item">
                <div class="item-right">
                  <el-input class="item-input" placeholder="请输入验证码" v-model="codeForm.captcha"></el-input>
                  <button class="code-button" :disabled="verifyCodeDisabled" @click="sendVerifyCode">{{
                      codeButtonText
                    }}
                  </button>
                </div>
              </div>
              <div class="agree-box">
                <i @click="agreeStatus = !agreeStatus" class="iconfont"
                   :class="agreeStatus ? 'active icon-danxuan-xuanzhong' : 'icon-danxuan-weixuanzhong'"></i>
                <div class="agree-text">
                  <span class="normal-text" @click="agreeStatus = !agreeStatus">我已阅读并同意</span>
                  <a :href="userAgreementUrl" target="_blank" class="normal-text color-primary">《威弘用户服务协议》</a>
                  <span class="normal-text">和</span>
                  <a href="https://static.weihong777.com/agree/index.html" target="_blank"
                     class="normal-text color-primary">《隐私政策》</a>
                </div>
              </div>
              <div class="button-box">
                <button class="button-item" :disabled="!loginButtonCanClick" @click="loginValidate">登录</button>
              </div>
            </div>
            <!-- 下载-->
            <div class="downLoad-box" v-show="activeTab === 'downLoad'">
              <div class="img-box">
                <img class="down-img" :src="androidDownloadImgUrl" alt="安卓下载" srcset=""/>
                <img class="down-img" :src="iosDownloadImgUrl" alt="ios下载" srcset=""/>
              </div>
              <div class="downLoad-text">扫码下载威弘APP</div>
            </div>
            <!--开通系统-->
            <div class="system-box" v-show="activeTab === 'system'">
              <home-system-apply-form ref="SystemApplyForm"/>
              <div class="system-button-box">
                <el-button class="button-item primary-outline-btn" @click="consultDialogShow = true">咨询</el-button>
                <el-button class="button-item" type="primary" @click="submitSystemApply">提交</el-button>
              </div>
            </div>
            <!-- 扫码咨询-->
            <div class="advice-box" v-show="activeTab === 'advice'">
              <div class="img-box">
                <img class="advice-img" :src="adviceWechatImgUrl" alt="扫码咨询" srcset=""/>
              </div>
              <div class="advice-text">微信扫码咨询</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    系统卡片展示区域-->
    <home-system-box/>
    <!-- 底部栏 -->
    <div class="page-footer">
      <span class="company-name">上海威弘信息科技有限公司</span>
      <a class="company-number" href="https://beian.miit.gov.cn" target="_blank">沪ICP备20016346号-1</a>
    </div>
    <!--    选择角色弹窗-->
    <el-dialog title="请选择登录的角色" :visible.sync="roleDialogShow" width="31.25vw" :before-close="closeRoleDialog">
      <div class="dialog-box">
        <div class="role-item" v-for="(item, index) in roleInfoList.roleVos" :key="index"
             @click="selectRoleInfoIndex = index">
          <div class="item-left">
            <div class="role-name">{{ item.roleName }}</div>
            <div class="role-desc" v-if="item.desc">{{ item.desc }}</div>
          </div>
          <div class="item-right">
            <i class="iconfont"
               :class="selectRoleInfoIndex === index ? 'active icon-danxuan-xuanzhong' : 'icon-danxuan-weixuanzhong'"></i>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="warning-text" v-if="warningTextShow">您未选择登录的角色</div>
        <el-button type="primary" @click="selectRoleConfirm">选好了</el-button>
      </div>
    </el-dialog>
    <!--    提示弹窗-->
    <el-dialog title="温馨提示" :visible.sync="tipDialogShow" width="31.25vw" :before-close="closeTipDialog">
      <div class="dialog-box">
        <div class="tip-text">{{ tipDialogText }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tipDialogShow = false">知道了</el-button>
      </span>
    </el-dialog>
    <!-- 咨询 -->
    <home-system-consult-dialog :show.sync="consultDialogShow"/>
  </div>
</template>

<script>
import VueQr from 'vue-qr'
import UUID from 'uuid-js'
import { mapState } from 'vuex'
import { validateRule } from '@/utils/validateRule'
import MultiRoleLogin from '@/mixin/MultiRoleLogin'
import HomeSystemBox from './HomeSystemBox'
import HomeSystemApplyForm from './HomeSystemApplyForm'
import HomeSystemConsultDialog from './HomeSystemConsultDialog'
import { closeSocketApi, connectionApi, subscribeCodeLoginApi } from '@/api/socket'
import { checkMobileFirstLoginApi, getRoleInfoApi, loginApi, loginByCodeApi, sendLoginVerifyCodeApi } from '@/api/login'
import { getUserDetailApi } from '@/api/user'

export default {
  name: 'Home',
  components: { VueQr, HomeSystemBox, HomeSystemApplyForm, HomeSystemConsultDialog },
  mixins: [MultiRoleLogin],
  data() {
    return {
      androidDownloadImgUrl: this.$transferQnyUrl('2310/image/f04a12e537d54caf81ce51e33b24ef4c.png'),
      iosDownloadImgUrl: this.$transferQnyUrl('2309/image/l/b42bd46158274c4397e456b2ee0c6f57.png'),
      adviceWechatImgUrl: this.$transferQnyUrl('c69f108c69714c0a954fd4acad65d412'),
      qrLogoUrl: this.$transferQnyUrl('8efe260f40d04357aff64369a47207eb'),
      tabList: [
        { label: '扫码登录', name: 'scan' },
        { label: '验证码登录', name: 'code' },
        { label: '密码登录', name: 'password' },
        { label: '下载威弘APP', name: 'downLoad' },
        { label: '开通系统', name: 'system' },
        { label: '扫码咨询', name: 'advice' }
      ],
      activeTab: 'scan',
      passwordForm: {
        loginName: '',
        password: ''
      },
      codeForm: {
        loginName: '',
        captcha: '',
        password: ''
      },
      agreeStatus: false,
      codeButtonText: '发送验证码',
      verifyCodeDisabled: false,
      roleDialogShow: false,
      roleInfoList: [],
      selectRoleInfoIndex: '',
      qrCodeText: '',
      margin: 0,
      isConnect: false,
      tipDialogShow: false,
      tipDialogText: '',
      warningTextShow: false,
      consultDialogShow: false
    }
  },
  computed: {
    ...mapState('common', ['screenWidth']),
    loginButtonCanClick() {
      if (this.activeTab === 'password') {
        if (!this.agreeStatus) {
          return false
        }
        return !(!this.passwordForm.loginName || !this.passwordForm.password)
      } else {
        if (!this.agreeStatus) {
          return false
        }
        return !(!this.codeForm.loginName || !this.codeForm.captcha)
      }
    },
    userAgreementUrl() {
      return `${process.env.VUE_APP_CENTER_LINK_URL}/UserAgreement`
    },
    qrWidth() {
      let width = Number(this.screenWidth)
      if (width > 1280) {
        width = 1280
      } else if (width < 512) {
        width = 512
      }
      return (width / 1920) * 400
    }
  },
  watch: {
    activeTab(val) {
      if (val === 'scan') {
        this.qrCodeText = JSON.stringify({ uuid: UUID.create(4).toString(), device: 'web_pc' })
        this.connectSocket()
      } else {
        if (this.isConnect) {
          closeSocketApi()
        }
      }
    }
  },
  created() {
    this.qrCodeText = JSON.stringify({ uuid: UUID.create(4).toString(), device: 'web_pc' })
    this.connectSocket()
    localStorage.clear()
    sessionStorage.clear()
  },
  methods: {
    // 连接socket
    connectSocket() {
      connectionApi(JSON.parse(this.qrCodeText).uuid).then(() => {
        this.isConnect = true
        subscribeCodeLoginApi((res) => {
          if (res.status !== 1) {
            return this.$message(res.msg)
          }
          const resultData = res.data
          this.getRoleInfo(resultData.userid)
          closeSocketApi()
          this.isConnect = false
        }, JSON.parse(this.qrCodeText).uuid)
      })
    },
    // 登陆校验
    loginValidate() {
      if (this.activeTab === 'password') {
        if (!validateRule.validateMobile(this.passwordForm.loginName)) {
          this.$message('请填写正确的手机号')
          return false
        }
        this.loginSubmit()
      }
      if (this.activeTab === 'code') {
        if (!validateRule.validateMobile(this.codeForm.loginName)) {
          this.$message('请填写正确的手机号')
          return false
        }
        this.loginSubmit()
      }
    },
    // 发送验证码
    async sendVerifyCode() {
      if (this.verifyCodeDisabled) {
        return false
      }
      if (!validateRule.validateMobile(this.codeForm.loginName)) {
        this.$message('请填写正确的手机号')
        return false
      }
      // 判断是否是首次登录，如果是首次登录，拦截并提示去APP操作
      const checkRes = await checkMobileFirstLoginApi(this.codeForm.loginName)
      if (checkRes.statusCode !== 200) {
        this.$message(checkRes.message)
        return false
      }
      if (checkRes.data.inited === 0) {
        this.tipDialogText = '你还未完成新员工注册，请下载企业悠订app完成注册后登录才可使用网页。'
        this.tipDialogShow = true
        return false
      }
      // 调用验证码接口
      const params = {
        isInside: 1,
        mobile: this.codeForm.loginName
      }
      const res = await sendLoginVerifyCodeApi(params)
      if (res.statusCode !== 200) {
        this.$message('验证码发送失败')
        return false
      }
      this.$message('验证码发送中')
      this.disableSendBtn()
    },
    // 验证码按钮
    disableSendBtn() {
      this.verifyCodeDisabled = true
      let countdownSeconds = 60
      const interval = setInterval(() => {
        countdownSeconds--
        if (countdownSeconds <= 0) {
          this.verifyCodeDisabled = false
          this.codeButtonText = '发送验证码'
          clearInterval(interval)
          return false
        }
        this.codeButtonText = `重新发送(${countdownSeconds})`
      }, 1000)
    },
    // 登录
    loginSubmit() {
      let loginApiName = loginApi
      if (this.activeTab === 'code') {
        loginApiName = loginByCodeApi
      }
      loginApiName(this.activeTab === 'code' ? this.codeForm : this.passwordForm.loginName, this.passwordForm.password).then((res) => {
        if (res.statusCode !== 200) {
          this.$message(res.message)
          return false
        }
        // inited 0没有设置过密码(跳转到设置新密码页面) 1设置过密码,没有确认个人信息 2正常流程
        if (res.data.inited === 0) {
          this.tipDialogText = '你还未完成新员工注册，请下载企业悠订app完成注册后登录才可使用网页。'
          this.tipDialogShow = true
          return false
        }
        this.getRoleInfo(res.data.userid)
      })
    },
    // 获取角色信息
    getRoleInfo(userId) {
      getRoleInfoApi(userId).then((res) => {
        if (res.statusCode !== 200) {
          this.$message(res.message)
          return false
        }
        const roleInfo = res.data
        if (this.getRoleNumber(roleInfo) > 1) {
          // 多角色，选择角色登录
          this.roleInfoList = roleInfo[0]
          this.selectRoleInfoIndex = ''
          this.roleDialogShow = true
        } else if (this.getRoleNumber(roleInfo) === 1) {
          // 单角色直接登录
          this.roleInfoList = roleInfo[0]
          this.selectRoleInfoIndex = 0
          this.roleDialogShow = false
          this.selectRoleConfirm()
        } else {
          this.$message('角色信息错误')
          return false
        }
      })
    },
    // 判断用户角色数量
    getRoleNumber(roleInfo) {
      let count = 0
      roleInfo.forEach((item) => {
        count = count + item.roleVos.length || 0
      })
      return count
    },
    // 角色选择确认
    selectRoleConfirm() {
      if (typeof this.selectRoleInfoIndex !== 'number') {
        this.warningTextShow = true
        return false
      }
      const info = this.roleInfoList.roleVos[this.selectRoleInfoIndex]
      getUserDetailApi(info.userId, info.roleId, info.reportUserType).then((res) => {
        if (res.statusCode !== 200) {
          this.$message(res.message)
          return false
        }
        // 登录之前先清除缓存
        localStorage.clear()
        // 官网不需要设置缓存，直接跳转pc-web登录中转页面
        this.goToCenterLogin(res.data.userid, res.data.roleid, info.reportUserType)
      })
    },
    goToCenterLogin(userId, roleId, reportUserType) {
      // // 登录之前先清除缓存
      // localStorage.clear()
      this.centerLogin(userId, roleId).then((res) => {
        // 如果是门店员工 登录门店
        if (res.usertype === 2) {
          this.tipDialogText = '门店员工暂不支持登录'
          this.tipDialogShow = true
          this.connectSocket()
          localStorage.clear()
          sessionStorage.clear()
          return false
        }
        // 跳转pc-web登录中转页面
        window.location.href = `${process.env.VUE_APP_PC_WEB_LINK_URL}/login/redirect?userId=${userId}&roleId=${roleId}&reportUserType=${reportUserType}`
      })
    },
    // 关闭弹框时，重新生成二维码
    closeRoleDialog() {
      this.roleDialogShow = false
      this.qrCodeText = JSON.stringify({ uuid: UUID.create(4).toString(), device: 'web_pc' })
      this.connectSocket()
    },
    closeTipDialog() {
      this.tipDialogShow = false
    },
    tabChange(tabItem) {
      if (tabItem.name === this.activeTab) {
        return false
      }
      this.activeTab = tabItem.name
    },
    // 提交申请
    submitSystemApply() {
      this.$refs.SystemApplyForm.submitApply()
    }
  }
}
</script>

<style lang="less" scoped>
.page-container {
  width: 100%;
  min-width: 1024px;
}

.page-header {
  min-height: 707px;
  /* 加载背景图 */
  background-image: url('../../assets/image/home-bg.png');
  /* 背景图垂直、水平均居中 */
  background-position: center 0;
  /* 背景图不平铺 */
  background-repeat: no-repeat;
  /* 当内容高度大于图片高度时，背景图像的位置相对于viewport固定 */
  //background-attachment: fixed;
  /* 让背景图基于容器大小伸缩 */
  background-size: cover;

  .page-header-content {
    width: 1680px;
    margin: 0 auto;
  }
}

.header-box {
  // padding: 50px 0 0 134px;
  padding-top: 50px;

  .header-title {
    display: flex;
    align-items: baseline;

    .title-text {
      color: @color-white;
      font-size: 60px;
      font-weight: 700;
    }

    .title-button {
      width: 164px;
      height: 40px;
      margin-left: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: @color-white;
      font-size: 28px;
      font-weight: 500;
      border-radius: 67px;
      border: 0.64px solid #979797;
      background: linear-gradient(138.7deg, #ffab00 1%, #ff8037 100%);
    }
  }

  .header-sub-title {
    margin-top: 32px;
    color: #f5f9ff;
    font-size: 21px;
    font-weight: 400;
    line-height: 30px;
  }

  .header-desc {
    margin-top: 8px;
    color: @color-white;
    font-size: 30px;
    font-weight: 500;
    line-height: 44px;
  }
}

.login-box {
  margin-top: 40px;
  // margin-left: 134px;
  margin-bottom: 40px;
  display: inline-flex;
  background: @color-white;
  border-radius: 8px;

  .login-tab {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .tab-item {
      width: 226px;
      padding: 36px 0 28px 0;
      display: flex;
      justify-content: center;
      border-bottom: 1px solid @color-border;

      .item-text {
        position: relative;

        .default-text {
          font-size: 24px;
          font-weight: 500;
          color: #888888;
          line-height: 32px;
        }

        .active-text {
          font-weight: 700 !important;
          color: @color-primary !important;
        }

        .active-line {
          position: absolute;
          bottom: -8px;
          height: -4px;
          width: 100%;
          background: @color-primary;
        }
      }
    }
  }

  .login-form {
    width: 640px;
    height: 602px;
    border-left: 1px solid @color-border;
    //扫码登录
    .qr-box {
      .img-box {
        margin-top: 60px;
        display: flex;
        justify-content: center;

        .qr-code {
          width: 400px;
          height: 400px;
        }
      }

      .qr-text {
        margin-top: 57px;
        font-size: 24px;
        color: @color-black;
        text-align: center;
      }
    }

    //密码登录
    .password-box,
    .code-box {
      padding: 100px 60px;

      .input-item {
        padding: 36px 0 28px 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid @color-border;

        .item-right {
          display: flex;
          flex: 1;
          align-items: center;

          .item-input {
            flex: 1;

            /deep/ .el-input__inner {
              padding-left: 0 !important;
              height: 32px !important;
              line-height: 32px !important;
              font-size: 24px;
              border-color: transparent;
            }

            /deep/ .el-input__icon {
              font-size: 24px !important;
            }
          }

          .code-button {
            height: 40px;
            padding: 0 8px;
            display: flex;
            align-items: center;
            font-size: 24px;
            font-weight: 500;
            color: @color-primary;
            background: @color-white;
            border-radius: 4px;
            border: 2px solid @color-primary;
          }
        }
      }
    }

    .system-box {
      padding: 24px 60px 0 60px;

      .input-item {
        padding: 24px 0 18px 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid @color-border;

        .item-right {
          display: flex;
          flex: 1;
          align-items: center;

          .item-input {
            flex: 1;

            /deep/ .el-input__inner {
              padding-left: 0 !important;
              height: 32px !important;
              line-height: 32px !important;
              font-size: 24px;
              border-color: transparent;
            }

            /deep/ .el-input__icon {
              font-size: 24px !important;
            }
          }

          .code-button {
            height: 40px;
            padding: 0 8px;
            display: flex;
            align-items: center;
            font-size: 24px;
            font-weight: 500;
            color: @color-primary;
            background: @color-white;
            border-radius: 4px;
            border: 2px solid @color-primary;
          }
        }
      }

      .select-row {
        padding: 24px 0 18px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid @color-border;

        .row-left {
          font-size: 24px;
          font-weight: 500;
          color: @color-text-placeholder;
          line-height: 32px;
        }

        .row-right {
          display: flex;
          align-items: center;

          .select-item {
            display: flex;
            align-items: center;

            .item-icon {
              margin-right: 16px;
              font-size: 30px;
              color: @color-text-placeholder;

              &.active {
                color: @color-primary !important;
              }
            }

            .item-text {
              font-size: 24px;
              font-weight: 500;
              color: @color-text-placeholder;
              line-height: 32px;

              &.active {
                color: @color-primary !important;
              }
            }

            &:last-of-type {
              margin-left: 40px;
            }
          }
        }
      }

      .system-agree-box {
        display: flex;
        align-items: center;
        margin-top: 24px;

        .iconfont {
          margin-right: 8px;
          font-size: 30px;
          color: @color-text-placeholder;

          &.active {
            color: @color-primary;
          }
        }

        .agree-text {
          flex: 1;

          .normal-text {
            font-size: 20px;
            font-weight: 500;
            color: @color-text-placeholder;
            line-height: 32px;
          }

          .color-primary {
            color: @color-primary;
          }
        }
      }

      .system-button-box {
        display: flex;
        margin-top: 24px;
        justify-content: center;

        .button-item {
          display: flex;
          width: 400px;
          height: 60px;
          line-height: 32px;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          font-weight: 700;
          // border: 0;
          border-radius: 8px;

          &.primary-outline-btn {
            color: @color-primary;
            border: 3px solid @color-primary;
          }
        }
      }
    }

    //协议
    .agree-box {
      display: flex;
      align-items: baseline;
      margin-top: 80px;

      .iconfont {
        margin-right: 8px;
        font-size: 20px;
        color: @color-text-placeholder;

        &.active {
          color: @color-primary;
        }
      }

      .agree-text {
        flex: 1;

        .normal-text {
          font-size: 24px;
          font-weight: 500;
          color: @color-text-placeholder;
          line-height: 32px;
        }

        .color-primary {
          color: @color-primary;
        }
      }
    }

    //登录按钮
    .button-box {
      margin-top: 24px;
      display: flex;
      justify-content: center;

      .button-item {
        width: 200px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: 700;
        color: @color-white;
        line-height: 32px;
        border: 0;
        border-radius: 4px;
        background: @color-primary;
      }
    }

    //下载
    .downLoad-box {
      .img-box {
        margin-top: 118px;
        padding: 0 59px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .down-img {
          width: 245px;
          height: 245px;
        }
      }

      .downLoad-text {
        margin-top: 125px;
        font-size: 24px;
        color: @color-black;
        font-weight: 400;
        text-align: center;
      }
    }

    //扫码咨询
    .advice-box {
      .img-box {
        margin-top: 65px;
        display: flex;
        justify-content: center;

        .advice-img {
          width: 400px;
          height: 400px;
        }
      }

      .advice-text {
        margin-top: 48px;
        font-size: 24px;
        color: @color-black;
        font-weight: 400;
        text-align: center;
      }
    }
  }
}

.dialog-box {
  max-height: 500px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0 !important;
  }

  .role-item {
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid @color-border;

    .item-left {
      .role-name {
        font-size: 20px;
        font-weight: 600;
        color: @color-text-main;
      }

      .role-desc {
        margin-top: 8px;
        padding-right: 20px;
        font-size: 16px;
        color: @color-text-sub;
      }
    }

    .item-right {
      .iconfont {
        font-size: 24px;

        &.active {
          color: @color-primary;
        }
      }
    }
  }

  .tip-text {
    font-size: 24px;
    color: @color-text-normal;
    line-height: 32px;
  }
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .warning-text {
    margin-right: 20px;
    color: #ff6c6c;
    font-size: 20px;
    font-weight: 400;
  }
}

.page-footer {
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: @color-white;

  .company-name {
    margin-right: 80px;
    font-size: 24px;
    color: @color-text-main;
    line-height: 40px;
  }

  .company-number {
    font-size: 24px;
    color: @color-text-main;
    line-height: 40px;
  }
}
</style>
