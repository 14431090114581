import request from '@/utils/request'

/**
 * 获取用户详情
 * @param userId
 * @param roleId
 * @param reportUserType
 * @returns {*}
 */
export function getUserDetailApi(userId, roleId, reportUserType) {
  return request({
    url: '/users/detail',
    method: 'get',
    params: { userId, roleId, reportUserType }
  })
}
