/*
 * @Author: hwu
 * @Date: 2023-08-10 13:57:04
 * @Description: file content
 * @LastEditTime: 2023-10-12 20:33:41
 */
const validateRule = {
  // 手机号
  validateMobile: function(val) {
    const rule = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
    return rule.test(val)
  },
  // 座机号
  validateLandline: function(val) {
    const rule = /^((0\d{2,3}\d{7,8}))$/
    return rule.test(val)
  },
  validatePassword: function(val) {
    const rule = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/
    return rule.test(val)
  },
  // 正整数
  validatePositiveInteger: function(val) {
    const rule = /^[1-9]\d*$/
    return rule.test(val)
  },
  // 大于0的两位小数
  validatePositiveTwoDecimal: function(val) {
    const rule = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/
    return rule.test(val)
  },
  // [0,100)的两位小数
  validateRateDecimal: function(val) {
    const rule = /(^(\d|[1-9]\d)(\.\d{1,2})?$)/
    return rule.test(val)
  },
  // 中文名称
  validateName: function(val) {
    const rule = /^[\u4e00-\u9fa5]+$/
    return rule.test(val)
  },
  // 身份证校验
  validateCardNo: function(val) {
    const rule = /(^\d{15}$)|(^\d{17}(\d|X)$)/
    return rule.test(val)
  }
}
export { validateRule }
