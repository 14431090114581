import { getCenterAppLoginDataApi } from '@/api/login'

export default {
  methods: {
    centerLogin(userId, roleId) {
      return new Promise((resolve) => {
        getCenterAppLoginDataApi(userId, roleId).then((response) => {
          if (response.status !== 1) {
            return this.$message(response.msg)
          }
          const resultData = response.data
          localStorage.setItem('userID', resultData.userid)
          localStorage.setItem('manager', resultData.manager)
          localStorage.setItem('roleId', resultData.roleid)
          localStorage.setItem('companyID', resultData.companyid)
          localStorage.setItem('companyName', resultData.companyName)
          localStorage.setItem('brandCode', resultData.brandcode)
          localStorage.setItem('brandName', resultData.brandname)
          // localStorage.setItem('def1', resultData.flag)
          localStorage.setItem('userDef1', resultData.def1)
          localStorage.setItem('logonName', resultData.logonname)
          localStorage.setItem('userName', resultData.usercnname)
          localStorage.setItem('storeCode', resultData.storecode)
          localStorage.setItem('storeName', resultData.storename)
          localStorage.setItem('userPhoto', resultData.photo)
          localStorage.setItem('nickName', resultData.nickName)
          localStorage.setItem('userType', resultData.usertype)
          localStorage.setItem('token', resultData.userid)
          localStorage.setItem('ehrInit', resultData.ehrInit)
          localStorage.setItem('departmentid', resultData.departmentid)
          localStorage.setItem('roleName', resultData.rolename)
          localStorage.setItem('Inited', resultData.inited)
          localStorage.setItem('financeStep', resultData.financeStep)
          localStorage.setItem('hrStep', resultData.hrStep)
          localStorage.setItem('ehrStep', resultData.ehrStep)
          localStorage.setItem('companyFirst', resultData.companyFirst)
          localStorage.setItem('firstLogin', resultData.inited === 0 ? 1 : 0)
          if (resultData.userRoleList && resultData.userRoleList.length > 0) {
            // 根据角色匹配默认首名牌
            const userRoleList = resultData.userRoleList
            const currentSelectRoleIdInfo = userRoleList.find((item) => item.roleId === roleId)
            if (currentSelectRoleIdInfo.roleBrandList && currentSelectRoleIdInfo.roleBrandList.length > 0) {
              const firstBrand = currentSelectRoleIdInfo.roleBrandList[0]
              localStorage.setItem('brandCode', firstBrand.brandCode)
              localStorage.setItem('brandName', firstBrand.brandName)
            }
          }
          resolve(resultData)
        })
      })
    }
  }
}
