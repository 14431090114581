import SockJs from 'sockjs-client'
import Stomp from 'stompjs'

let stompClient

export const connectionApi = (clientId) => {
  const socket = new SockJs(process.env.VUE_APP_CENTER_DOMAIN + '/center-websocket')
  stompClient = Stomp.over(socket)
  stompClient.debug = function() {}
  const headers = {
    clientId
  }
  return new Promise((resolve, reject) => {
    stompClient.connect(
      headers,
      () => {
        resolve()
      },
      (err) => {
        reject(err)
      }
    )
  })
}

export const closeSocketApi = () => {
  if (stompClient !== null) {
    stompClient.disconnect()
  }
}

/**
 *
 * @param {Function} callback 数据变化后的回调方法
 * @param clientId
 */
export const subscribeCodeLoginApi = (callback, clientId) => {
  stompClient.subscribe('/topic/login/' + clientId, (res) => {
    callback(JSON.parse(res.body))
  })
}
