import axios from 'axios'
import { Message } from 'element-ui'
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 300000,
  headers: {
    'Content-Type': 'application/json'
  }
})

// request拦截器
service.interceptors.request.use(
  config => {
    // 这里可以增加全局的拦截，取缓存中的token存入header
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// respone拦截器
service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    if (error.message) {
      Message.error(error.message)
    } else {
      Message.error('Server Error')
    }
    // 这里可以增加全局的错误提示
    Promise.reject(error)
  }
)

export default service
