<!--
 * @Author: hwu
 * @Date: 2023-12-19 16:04:24
 * @Description: 首页 -> 系统申请开通表单
 * @LastEditTime: 2024-03-04 14:53:14
-->
<template>
  <div class="wh-form-box">
    <div class="wh-form-item">
      <el-input class="wh-form-item_input" placeholder="品牌名称 *" v-model="systemForm.brandName" type="text" clearable />
    </div>
    <div class="wh-form-item">
      <el-input class="wh-form-item_input" placeholder="申请人姓名 *" v-model="systemForm.userName" type="text" clearable />
    </div>
    <div class="wh-form-item">
      <div class="wh-form-item_label" :class="{ 'color-text-normal': systemForm.gender }">性别 *</div>
      <div class="wh-form-item_content">
        <div class="wh-radio-box">
          <div class="wh-radio-item" :class="{ active: systemForm.gender === 1 }" @click="checkGender(1)">
            <i class="wh-radio-item_icon iconfont" :class="systemForm.gender === 1 ? 'icon-danxuan-xuanzhong' : 'icon-danxuan-weixuanzhong'"></i>
            <span class="wh-radio-item_text">男</span>
          </div>
          <div class="wh-radio-item" :class="{ active: systemForm.gender === 2 }" @click="checkGender(2)">
            <i class="wh-radio-item_icon iconfont" :class="systemForm.gender === 2 ? 'icon-danxuan-xuanzhong' : 'icon-danxuan-weixuanzhong'"></i>
            <span class="wh-radio-item_text">女</span>
          </div>
        </div>
      </div>
    </div>
    <div class="wh-form-item">
      <div class="wh-form-item_content">
        <el-input class="wh-form-item_input" placeholder="手机号 *" v-model="systemForm.userPhone" type="tel" maxlength="11" clearable />
      </div>
    </div>
    <div class="wh-form-item">
      <div class="wh-form-item_content">
        <el-input class="wh-form-item_input" placeholder="验证码 *" v-model="verifyCode" />
        <el-button class="wh-form-item_input-btn" type="primary" size="mini" :disabled="getVerifyDisabled" @click="sendVerifyCode">{{ verifyBtnText }}</el-button>
      </div>
    </div>
    <div class="wh-form-item">
      <div class="wh-form-item_content">
        <el-input class="wh-form-item_input" placeholder="总部所在城市 *" v-model="systemForm.cityName" type="text" clearable />
      </div>
    </div>
    <div class="wh-form-agree">
      <i class="iconfont" :class="agreeStatus ? 'active icon-danxuan-xuanzhong' : 'icon-danxuan-weixuanzhong'" @click="agreeStatus = !agreeStatus"></i>
      <div class="wh-form-item_agree-text">
        <span @click="agreeStatus = !agreeStatus">我已阅读并同意</span>
        <a class="color-primary" href="https://static.weihong777.com/agree/index.html" target="_blank">《个人消息与隐私保护条款》</a>
      </div>
    </div>
    <el-dialog class="wh-dialog" :visible.sync="errorDialogShow" title="提示" width="30%" :show-close="false">
      <div class="wh-dialog-body">
        <div class="wh-dialog-text">{{ systemForm.brandName }}已开通《威弘系统》，不可重复开通；</div>
        <div class="wh-dialog-text" v-if="submitBackData.complaintPhoneNumber">威弘投诉电话：{{ submitBackData.complaintPhoneNumber }}</div>
      </div>
      <span slot="footer" class="wh-dialog-footer">
        <el-button type="primary" size="small" @click="errorDialogShow = false">知道了</el-button>
      </span>
    </el-dialog>
    <el-dialog class="wh-dialog" :visible.sync="successDialogShow" title="提示" width="30%" :show-close="false">
      <div class="wh-dialog-body">
        <div class="wh-dialog-text">请扫码下载“威弘app”，并使用手机号{{ systemForm.userPhone }}授权登录后，即可自助开通系统；</div>
        <div class="qrcode-box">
          <vue-qr :logoSrc="qrLogoUrl" :logoMargin="4" :margin="0" :text="qrLogoUrl" :size="qrWidth"></vue-qr>
        </div>
      </div>
      <div slot="footer" class="wh-dialog-footer">
        <el-button type="primary" size="small" @click="successDialogShow = false">知道了</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import VueQr from 'vue-qr'
import { mapState } from 'vuex'
import { validateRule } from '@/utils/validateRule'
import { applySystemApi } from '@/api/apply'
import { sendVerifyCodeApi, checkVerifyCodeApi } from '@/api/login'
export default {
  components: { VueQr },
  data() {
    return {
      systemForm: {
        brandName: '',
        userName: '',
        userPhone: '',
        gender: '',
        province: '',
        provinceName: '',
        city: '',
        cityName: '',
        promotionUserId: '',
        forwardRecordId: '',
        resource: 1,
        applySystemName: ''
      },
      verifyCode: '',
      verifyBtnText: '获取验证码',
      verifyDisabled: false,
      countdownSeconds: 0,
      agreeStatus: false,
      errorDialogShow: false,
      successDialogShow: false,
      qrLogoUrl: this.$transferQnyUrl('8efe260f40d04357aff64369a47207eb'),
      submitBackData: {},
      submitDisabled: false
    }
  },
  computed: {
    ...mapState('common', ['screenWidth']),
    getVerifyDisabled() {
      if (!validateRule.validateMobile(this.systemForm.userPhone)) {
        return true
      }
      return this.verifyDisabled
    },
    qrWidth() {
      let width = Number(this.screenWidth)
      if (width > 1920 / 2) {
        width = 1920 / 2
      }
      return (width / 1920) * 260
    }
  },
  methods: {
    // 发送验证码
    sendVerifyCode() {
      if (this.getVerifyDisabled) {
        return false
      }
      sendVerifyCodeApi(this.systemForm.userPhone, 0).then((res) => {
        if (res.status !== 1) {
          this.$message.error('验证码发送失败')
          return false
        }
        this.timeDownTick()
      })
    },
    // 验证码按钮
    timeDownTick() {
      this.verifyDisabled = true
      this.countdownSeconds = 60
      const interval = setInterval(() => {
        this.countdownSeconds--
        if (this.countdownSeconds <= 0) {
          this.verifyDisabled = false
          this.verifyBtnText = '获取验证码'
          clearInterval(interval)
          return false
        }
        this.verifyBtnText = `重新发送(${this.countdownSeconds})`
      }, 1000)
    },
    checkGender(gender) {
      this.systemForm.gender = gender
    },
    validForm() {
      if (!this.systemForm.brandName) {
        return '请填写品牌简称'
      }
      if (!this.systemForm.userName) {
        return '请填写姓名'
      }
      if (!this.systemForm.gender) {
        return '请选择性别'
      }
      if (!this.systemForm.userPhone) {
        return '请填写手机号'
      }
      if (!this.verifyCode) {
        return '请填写验证码'
      }
      if (!this.systemForm.cityName) {
        return '请选择城市'
      }
      if (!this.agreeStatus) {
        return '请阅读并勾选协议'
      }
      return ''
    },
    submitApply() {
      const errMsg = this.validForm()
      if (errMsg) {
        this.$message.error(errMsg)
        return
      }

      if (this.submitDisabled) {
        return false
      }

      this.submitDisabled = true
      checkVerifyCodeApi(this.systemForm.userPhone, this.verifyCode)
        .then((r0) => {
          if (r0.status !== 1) {
            this.$message.error('验证码错误')
            this.submitDisabled = false
            return false
          }
          if (this.selectedSystem && this.selectedSystem.productName) {
            this.systemForm.applySystemName = this.selectedSystem.productName
          }
          applySystemApi(this.systemForm)
            .then((res) => {
              this.submitDisabled = false
              this.submitBackData = res.data || {}
              if (res.status !== '1') {
                // 重复提交
                if (res.status === '-2') {
                  this.errorDialogShow = true
                  return
                }
                this.$message.error(res.message || '请求失败')
                return
              }
              this.successDialogShow = true
            })
            .catch(() => {
              this.submitDisabled = false
              this.$message.error('请求异常')
            })
        })
        .catch(() => {
          this.$message.error('验证码错误')
          this.submitDisabled = false
        })
    },
    closeSuccessDialog() {
      this.successDialogShow = false
      this.initDataForm()
      this.$emit('onSuccess', this.submitBackData)
    },
    initDataForm() {
      this.systemForm = {
        brandName: '',
        userName: '',
        userPhone: '',
        gender: '',
        province: '',
        provinceName: '',
        city: '',
        cityName: '',
        promotionUserId: this.systemForm.promotionUserId,
        forwardRecordId: this.systemForm.forwardRecordId,
        applySystemName: '',
        resource: this.systemForm.resource
      }
      this.verifyCode = ''
      this.countdownSeconds = 0
    }
  }
}
</script>
<style lang="less" scoped>
.qrcode-box {
  margin-top: 24px;
  text-align: center;
}
</style>
