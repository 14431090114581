import CryptoJS from 'crypto-js' // 引用AES源码js

const key = CryptoJS.enc.Utf8.parse('68616E6D61688882') // 十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse('68777568616E7E91') // 十六位十六进制数作为密钥偏移量
const universalContent = 'hanma_center_hwu_2019'

// 解密方法
function Decrypt(word) {
  const encryptedHexStr = CryptoJS.enc.Hex.parse(word)
  const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr)
  const decrypt = CryptoJS.AES.decrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  return decryptedStr.toString()
}

// 加密方法
function Encrypt(word) {
  const srcs = CryptoJS.enc.Utf8.parse(word)
  const encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.ciphertext.toString().toUpperCase()
}

function GenUniversalToken() {
  const currentTime = new Date().getTime()
  const json = {
    content: universalContent,
    date: currentTime
  }
  const jsonStr = JSON.stringify(json)
  return Encrypt(jsonStr)
}

export default {
  Decrypt,
  Encrypt,
  GenUniversalToken
}
