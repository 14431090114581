import request from '@/utils/request'
import secret from '@/utils/secret'

/**
 * 密码登录
 * @param loginName
 * @param password
 * @returns {*}inited 0没有设置过密码(跳转到设置新密码页面) 1设置过密码,没有确认个人信息 2正常流程
 */
export function loginApi(loginName, password) {
  return request({
    url: '/users/login',
    method: 'get',
    params: { loginName, password }
  })
}

/**
 * 验证码登录
 * @param data
 * @returns {*}inited 0没有设置过密码(跳转到设置新密码页面) 1设置过密码,没有确认个人信息 2正常流程
 */
export function loginByCodeApi(data) {
  return request({
    url: '/users/loginByCode',
    method: 'post',
    data
  })
}

/**
 * 用户角色列表
 * @param userId
 * @returns {*}
 */
export function getRoleInfoApi(userId) {
  return request({
    url: '/users/roleInfo',
    method: 'get',
    params: { userId }
  })
}

/**
 * 发送验证码
 * @param data
 * @returns {*}
 */
export function sendLoginVerifyCodeApi(data) {
  return request({
    url: '/users/sendMsg',
    method: 'post',
    data
  })
}

/**
 * 发送验证码
 * @param mobile 验证码手机号
 * @param token token
 * @param isInside is inside
 * @returns {*}
 */
export function sendVerifyCodeApi(mobile, isInside) {
  const token = secret.GenUniversalToken()
  return request({
    baseURL: process.env.VUE_APP_CENTER_API,
    url: '/boss/sendMsg',
    method: 'post',
    params: { mobile, token, isInside }
  })
}

/**
 * 校验手机收到的验证码是否有效
 * @param mobile
 * @param code
 * @returns
 */
export function checkVerifyCodeApi(mobile, code) {
  return request({
    baseURL: process.env.VUE_APP_CENTER_API,
    url: '/boss/verify/code/available',
    method: 'get',
    params: { mobile, code }
  })
}

/**
 * 对接center的登录
 * @param userId
 * @param roleId
 * @returns {*}
 */
export function getCenterAppLoginDataApi(userId, roleId) {
  return request({
    baseURL: process.env.VUE_APP_CENTER_API,
    url: '/account/app/login/employee/detail',
    method: 'post',
    data: {
      userId,
      roleId
    }
  })
}

/**
 * 对接storeApp的登录
 * @param userId
 * @param roleId
 * @returns {Promise<Response>}
 */
export function getStoreAppLoginDataApi(userId, roleId) {
  return request({
    baseURL: process.env.VUE_APP_STORE_APP_API,
    url: '/account/app/login/employee/detail',
    method: 'post',
    data: {
      userId,
      roleId
    }
  })
}

/**
 * 是否是首次登录
 * @param mobile
 * @returns {*} inited 0没有设置过密码(跳转到设置新密码页面) 1设置过密码,没有确认个人信息 2正常流程
 */
export function checkMobileFirstLoginApi(mobile) {
  return request({
    url: '/users/pc',
    method: 'get',
    params: { mobile }
  })
}
