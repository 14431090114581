<!--
 * @Author: hwu
 * @Date: 2023-12-19 16:04:24
 * @Description: 首页 -> 系统咨询弹窗
 * @LastEditTime: 2024-02-28 18:25:45
-->
<template>
  <el-dialog class="wh-dialog" :visible.sync="show" title="咨询" :show-close="false" width="30%">
    <div class="wh-dialog-body">
      <div class="consult-box">
        <div class="consult-label">系统功能咨询</div>
        <div class="consult-input">
          <el-input v-model="consultMsg" class="wh-form-item_input" placeholder="请输入针对系统功能的咨询" type="textarea" rows="4" resize="none" clearable />
        </div>
      </div>
      <div class="consult-box">
        <div class="consult-label">
          <span class="consult-label-text">手机号</span>
          <span class="consult-label-desc">（我们将以电话的形式回复您）</span>
        </div>
        <div class="consult-input">
          <el-input v-model="mobile" class="wh-form-item_input" placeholder="请输入您的手机号" type="tel" maxlength="11" clearable />
        </div>
      </div>
    </div>
    <div slot="footer" class="wh-dialog-footer">
      <el-button size="small" @click="onClose">返回</el-button>
      <el-button size="small" type="primary" @click="submitForm">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { validateRule } from '@/utils/validateRule'
import { saveConsultApi } from '@/api/apply'
export default {
  props: {
    show: { type: Boolean, required: true, default: false }
  },
  data() {
    return {
      consultMsg: '',
      mobile: ''
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.consultMsg = ''
        this.mobile = ''
      }
    }
  },
  methods: {
    onClose() {
      this.$emit('update:show', false)
    },
    submitForm() {
      if (!this.consultMsg) {
        this.$message.error('请输入要咨询的问题')
        return false
      }
      if (!validateRule.validateMobile(this.mobile)) {
        this.$message.error('请输入正确的手机号')
        return false
      }
      const data = {
        list: [
          {
            type: 'CONSULT',
            content: this.consultMsg
          }
        ],
        sourceClient: 'C',
        mobile: this.mobile
      }
      saveConsultApi(data).then((res) => {
        if (res.status !== '1') {
          this.$toast(res.message || '请求失败')
          return false
        }
        this.$alert('您的咨询将在12小时内以电话形式回复您，请注意接听。', '提示', {
          confirmButtonText: '知道了',
          callback: (action) => {
            this.onClose()
          }
        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.consult-box {
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  .consult-label {
    margin-bottom: 16px;
    line-height: 32px;
    font-size: 26px;
    font-weight: 500;
    color: @color-text-main;
    .consult-label-desc {
      font-size: 22px;
      font-weight: 400;
      color: @color-text-sub;
    }
  }
  .consult-input {
    /deep/ .el-textarea__inner,
    /deep/ .el-input__inner {
      padding: 16px;
      line-height: 32px;
      font-size: 22px;
      background-color: @color-background-sub;
      color: @color-text-normal;
      border: unset;
      &::-webkit-input-placeholder {
        color: @color-text-sub !important;
        font-weight: 400;
      }
    }
    /deep/ .el-input__inner {
      height: 64px !important;
    }
    /deep/ .el-input__icon {
      font-size: 24px !important;
      line-height: 64px !important;
    }
  }
}
</style>
